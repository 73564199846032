(function(window, document, undefined) {
  'use strict';

  function init() {
    addAnchorsTargetBlank();
    addHeaderAnchors();
    addShowLinks();
    removeCodeCommentsLineBreak();
  }

  init();

  /**
   * Adds `target="_blank"` and `rel="noopener"` to all external links for opening them in new tabs
   *
   * See: https://robwise.github.io/blog/javascript-external-links-open-new-window#straight-javascript
   *
   * Note: Not the most elegant implementation
   *
   */
  function addAnchorsTargetBlank() {
    let pattern = new RegExp(
      '^((((http:\\/\\/|https:\\/\\/)(www\\.)?)?' + window.location.hostname + ')|(localhost:\\d{4})|(\\/.*))(\\/.*)?$',
      ''
    );

    let anchors = document.querySelectorAll('a');
    let len = anchors.length;

    for (let i = 0; i < len; i++) {
      let element = anchors[i],
        href = element.getAttribute('href'),
        isSelf = element.getAttribute('target') === '_self';

      if (!pattern.test(href) && !isSelf & (href.indexOf('#') !== 0)) {
        element.setAttribute('target', '_blank');
        element.setAttribute('ref', 'noopener');
      }
    }
  }

  /**
   * Adds anchors to headers for deep hash linking.
   *
   * See: https://github.com/toddmotto/linkjuice#api
   *
   * Uses inline SVG for hover manipulation.
   *
   */
  function addHeaderAnchors() {
    linkjuice.init('.post__content', {
      selectors: ['h2', 'h3', 'h4', 'h5'],
      contentFn: ele => `
				<a href="#${ele.id}" class="deeplink" title="${ele.innerHTML}">
					<span class="deeplink-icon">
						<svg class="deeplink-icon__image" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.829 19.233l1.027 1.53c-2.26 1.184-4.799 1.602-6.851.961l6.709-13.886c1.049-2.148 2.947-1.474 3.949-3.521.207-.423.305-.872.305-1.314.001-1.681-1.365-3.003-2.994-3.003-1.734 0-2.974 1.445-2.974 3.055 0 1.274.716 2.272-.082 3.903l-6.709 13.889c-1.762-1.229-3-3.596-3.449-6.104l1.848-.146-3.134-4.542-2.474 4.924 1.746-.156c.602 3.721 2.521 6.808 5.482 8.258 1.264.619 2.62.919 4.016.919 1.877 0 3.828-.542 5.724-1.577l.995 1.474 2.037-5.049-5.171.385zm2.171-17.234c.547 0 1 .444 1 1.001 0 .54-.441.999-1.004.999-.551 0-.996-.449-.996-1.002 0-.547.443-.998 1-.998z"/></svg>
					</span>
				</a>
				${ele.innerHTML}
			`
    });
  }

  /**
   * Removes all the extra linebreaks added by Jekyll to code comments.
   *
   */
  function removeCodeCommentsLineBreak() {
    document.querySelectorAll('.c1').forEach(comment => (comment.innerHTML = comment.innerHTML.trim()));
  }

  /**
   * Shows an element by anchor tag with an `href` to the id of a hidden element.
   *
   */
  function addShowLinks() {
    Array.from(document.getElementsByTagName('a'))
      .filter(a => {
        let cl = a.getAttribute('class');
        return (!cl || cl.indexOf('deeplink') < 0);
      })
      .filter(a => a.getAttribute('href').indexOf('#') == 0)
      .forEach(a => {
        a.addEventListener('click', evt => {
          evt.preventDefault();
          let id = evt.target.getAttribute('href').substring(1);

          toggleClass(document.getElementById(id), 'show');
        });
      });
  }

  /**
   * Adds or removes a class to an element.
   *
   * Reference: http://youmightnotneedjquery.com/#toggle_class
   *
   * @param {any} el
   * @param {any} className
   */
  function toggleClass(el, className) {
    if (el.classList) {
      el.classList.toggle(className);
    } else {
      var classes = el.className.split(' ');
      var existingIndex = classes.indexOf(className);

      if (existingIndex >= 0) classes.splice(existingIndex, 1);
      else classes.push(className);

      el.className = classes.join(' ');
    }
  }
})(window, document);
