(function (window, document, undefined) {
	'use strict';

    /**
     * Configuration for Gist List
     */
    const gistPage = 'stash',
        username = 'patmigliaccio',
        gistCount = 5,
        excludeIfDescriptionContains = 'patmigliaccio.com',
        gistListId = 'gist-list',
        gistListLengthId = 'gist-list-length',
        gistListTitleId = 'gist-list-title';

    /**
     * Initialize Gist List
     * 
     * @returns 
     */
    function init() {
        if (!window.location.href || window.location.href.indexOf(gistPage) < 0){
            return;
        }

        getGists(username, gistCount, excludeIfDescriptionContains)
            .then(generateGistList)
            .then(gists => {
                document.getElementById(gistListLengthId).innerHTML = gists.length;
                document.getElementById(gistListId).innerHTML = gists.html;
                document.getElementById(gistListTitleId).style.display = 'block';
            })
            .catch(console.error);
    }

    init();
    
    /**
     * Retrieves the top `gistCount` gists for a specific username.
     * 
     * *Note: public gists only.*
     * 
     * @param {string} username 
     * @param {number} gistCount 
     * @returns {Promise<Array<Gist>>}
     */
    function getGists(username, gistCount, excludeIfDescriptionContains) {
        return xhr({url: `//api.github.com/users/${username}/gists`})
            .then(response => {
                return JSON.parse(response)
                    .filter(gist => {
                        return gist.description.indexOf(excludeIfDescriptionContains) < 0;
                    })
                    .slice(0, gistCount);
            });
    }
    
    /**
     * Creates HTML unordered list `<ul>` based on the array of gists passed in
     * 
     * @param {Array<gist>} gists 
     * @returns {GistListResponse} HTML and gists length
     */
    function generateGistList(gists) {
        let outputHTML = '<ul>';

        gists.forEach((gist, i) => {
            let id = i + 1;

            outputHTML += `
                <li>
                    <a class="gist__url" href="${gist.html_url}" target="_blank" rel="noopener">
                        <span id="gist-file-${id}" class="gist__file">${Object.keys(gist.files)[0]}</span>
                    </a>
                    <span id="gist-desc-${id}" class="gist__desc">${gist.description}</span>
                </li>
            `;
        });

        return { html: outputHTML += '</ul>', length: gists.length };
    }

    /**
     * Simple HTTP Request method
     * 
     * @param {RequestConfig}
     * @returns {Promise<Response>}
     */
    function xhr({method = 'GET', url, async = true, responseType = ''}){
        let req = new XMLHttpRequest();
        req.open(method, url, async);
        req.responseType = responseType;
    
        let p = new Promise((resolve, reject) => {
            req.onreadystatechange = () => {
                if (req.readyState == XMLHttpRequest.DONE){
                    if (200 <= req.status && req.status < 300){
                        resolve(req.response);
                    } else {
                        reject(req.response);
                    }
                }
            }
            
            req.send();
        });
        
        return p;
    }
	

})(window, document);