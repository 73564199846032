// See: https://css-tricks.com/almanac/properties/t/tab-size/

(function(window, document, undefined){
	'use strict';

	var tabSize = 4;
	var codeElements = document.getElementsByTagName('code'),
		textareas = document.getElementsByTagName('textarea'),
		e = document.createElement('i');

	if (e.style.tabSize !== '' && e.style.MozTabSize !== '' && e.style.oTabSize !== ''){
		replaceTabs(codeElements);
		replaceTabs(textareas);
	}

	function replaceTabs(ele){
		for (var i = 0; i < ele.length; i++){
			ele[i].innerHTML = ele[i].innerHTML.replace(/\t/g, repeat(" ", tabSize));
		}
	}

	function repeat(st, n){
		var s = "";
		while (--n >= 0){
			s += st
		}
		return s;
	}

})(window, document);